<template>
  <div>
    <ClientHeader :client="client" />
    <v-row>
      <v-col cols="12" md="6">
        <v-sheet elevation="1"  v-if="loadingScreen">
          <v-skeleton-loader type="article, actions"></v-skeleton-loader>
        </v-sheet>
        <ClientPackages
          v-else
          :clientId="clientId"
          :client="client"
          :generatePackageButtonDisabled="generatePackageButtonDisabled"
          :isAdmin="usersRole === 1"
        />
      </v-col>
    </v-row>
  </div>
</template>
<script>
import { mapGetters } from "vuex";
import AuthService from "@/services/auth.service";
import ClientService from "@/services/clients.service";
import ClientPackages from "@/components/Client/Profile/ClientPackages.vue";
import ClientHeader from "@/components/Client/ClientHeader.vue";

export default {
  name: "Packages",
  components: { ClientHeader, ClientPackages },
  data: () => ({
    clientId: null,
    usersRole: null,
    loadingScreen: false,
    rounds: [],
    generatePackageButtonLoading: false,
    client: null,
  }),
  methods: {
    getUser() {
      return AuthService.getCurrentUser(this.currentUser.current_user_id).then((res) => {
        this.usersRole = res.data.role_id;
      });
    },
    getCurrentClient() {
      return ClientService.getOneClient(this.clientId)
        .then((data) => {
          this.client = data;
          this.activeClient = this.client.active === 1 ? true : false;
        })
        .catch((error) => {
          this.$store.dispatch("snackbar/showSnackbarMessage", {
            message: `${error.response.data.error}. Please, try again.`,
            duration: 4000,
            mode: "fail",
          });
        });
    },
    getAllRounds() {
      return ClientService.getRounds({ clientId: this.clientId }).then((data) => {
        this.rounds = data;
      });
    },
  },
  computed: {
    ...mapGetters({
      currentUser: "auth/getCurrentUser",
    }),
    roundOne() {
      return this.rounds.filter((round) => round.number === 1)[0];
    },

    roundTwo() {
      return this.rounds.filter((round) => round.number === 2)[0];
    },
    generatePackageButtonDisabled() {
      return (
        (!this.roundOne && !this.roundTwo) ||
        !this.client.csv_upload.id
      );
    },
  },
  async created() {
    this.clientId = this.$route.params.clientId;
    this.loadingScreen = true;
    try {
      await this.getUser();
      await this.getCurrentClient();
      await this.getAllRounds();
    } finally {
      this.loadingScreen = false;
    }
  },
};
</script>
