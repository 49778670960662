<script>
import AdoptionReview from "@/components/SinglePostAdoptFirm/AdoptionReview.vue";
import IntroLetter from "@/components/SinglePostAdoptFirm/IntroLetter.vue";
import IntroEmail from "@/components/SinglePostAdoptFirm/IntroEmail.vue";
import ContactInitiated from "@/components/SinglePostAdoptFirm/ContactInitiated.vue";
import DocumentVerification from "@/components/SinglePostAdoptFirm/DocumentVerification.vue";
import Problematic from "@/components/SinglePostAdoptFirm/Problematic.vue";
import WaitingSigned8821 from "@/components/SinglePostAdoptFirm/WaitingSigned8821.vue";
import WaitingToBeFiled from "@/components/SinglePostAdoptFirm/WaitingToBeFiled.vue";
import FiledWithIRS from "@/components/SinglePostAdoptFirm/FiledWithIRS.vue";
import AwaitingIRSPayments from "@/components/SinglePostAdoptFirm/AwaitingIRSPayments.vue";
import RefileNeeded from "@/components/SinglePostAdoptFirm/RefileNeeded.vue";
import UnresponsiveClientsSecondAttempt from "@/components/SinglePostAdoptFirm/UnresponsiveClientsSecondAttempt.vue";
import UnresponsiveClients from "@/components/SinglePostAdoptFirm/UnresponsiveClients.vue";
import UnresponsiveClientsThirdAttempt from "@/components/SinglePostAdoptFirm/UnresponsiveClientsThirdAttempt.vue";
import CollectingTranscript from "@/components/SinglePostAdoptFirm/CollectingTranscript.vue";
import TranscriptVerified from "@/components/SinglePostAdoptFirm/TranscriptVerified.vue";
import AdoptionsService from "@/services/adoptions.service";
import ClientsService from "@/services/clients.service";
import DenialReceived from "@/components/SinglePostAdoptFirm/DenialReceived.vue";
import PaymentReceived from "@/components/SinglePostAdoptFirm/PaymentReceived.vue";

export default {
  name: "SingleAdoptedFirm",
  components: { AdoptionReview, IntroLetter, IntroEmail, ContactInitiated },
  data: () => ({
    entityId: "",
    component: null,
    loading: true,
    stats: [],
    selectedStatus: 0,
    entity: {},
  }),
  methods: {
    loadInitialComponent() {
      const component = this.$route.hash.substring(1);

      switch (component) {
        case "adoption-review":
          this.component = AdoptionReview;
          break;
        case "intro-letter":
          this.component = IntroLetter;
          break;
        case "intro-email":
          this.component = IntroEmail;
          break;
        case "contact-initiated":
          this.component = ContactInitiated;
          break;
        case "document-verification":
          this.component = DocumentVerification;
          break;
        case "unresponsive-clients":
          this.component = UnresponsiveClients;
          break;
        case "unresponsive-clients-second-attempt":
          this.component = UnresponsiveClientsSecondAttempt;
          break;
        case "unresponsive-clients-third-attempt":
          this.component = UnresponsiveClientsThirdAttempt;
          break;
        case "Problematic":
          this.component = Problematic;
          break;
        case "waiting-signed-8821":
          this.component = WaitingSigned8821;
          break;
        case "waiting-to-be-filed":
          this.component = WaitingToBeFiled;
          break;
        case "filed-with-irs":
          this.component = FiledWithIRS;
          break;
        case "collecting-transcript":
          this.component = CollectingTranscript;
          break;
        case "transcript-verified":
          this.component = TranscriptVerified;
          break;
        case "awaiting-irs-payments":
          this.component = AwaitingIRSPayments;
          break;
        case "refile-needed":
          this.component = RefileNeeded;
          break;
        case "denial-received":
          this.component = DenialReceived;
          break;
        case "payment-received":
          this.component = PaymentReceived;
          break;
        default:
          this.component = null;
      }
    },
    loadComponent(status) {
      this.selectedStatus = status;
      switch (status) {
        case 1:
          this.component = AdoptionReview;
          break;
        case 3:
          this.component = IntroLetter;
          break;
        case 4:
          this.component = IntroEmail;
          break;
        case 5:
          this.component = ContactInitiated;
          break;
        case 6:
          this.component = DocumentVerification;
          break;
        case 7:
          this.component = UnresponsiveClients;
          break;
        case 8:
          this.component = UnresponsiveClientsSecondAttempt;
          break;
        case 9:
          this.component = UnresponsiveClientsThirdAttempt;
          break;
        case 10:
          this.component = Problematic;
          break;
        case 11:
          this.component = WaitingSigned8821;
          break;
        case 12:
          this.component = WaitingToBeFiled;
          break;
        case 13:
          this.component = FiledWithIRS;
          break;
        case 14:
          this.component = CollectingTranscript;
          break;
        case 15:
          this.component = TranscriptVerified;
          break;
        case 16:
          this.component = AwaitingIRSPayments;
          break;
        case 17:
          this.component = RefileNeeded;
          break;
        case 18:
          this.component = DenialReceived;
          break;
        case 19:
          this.component = PaymentReceived;
          break;
      }
      window.scroll(0, 0);
    },
    async loadAdoptionStats() {
      try {
        this.stats = await AdoptionsService.getAdoptionStats(this.entityId);
      } catch (e) {
        console.error(e);
      }
    },
    async loadEntity() {
      this.entity = await ClientsService.getEntityById(this.entityId);
    },
    handleBackClick() {
      this.component = null
      this.loadAdoptionStats();
    }
  },
  async mounted() {
    this.entityId = this.$route.params.entityId;
    if (this.$route.hash)
      this.loadInitialComponent();

    await this.loadAdoptionStats();
    await this.loadEntity();
    this.loading = false;

  },
};
</script>

<template>
  <div>
    <v-overlay :value="loading">
      <v-progress-circular
        :size="30"
        :width="3"
        color="white"
        indeterminate
      ></v-progress-circular>
    </v-overlay>
    <v-row v-if="!loading">
      <v-col cols="12">
        <div class="d-flex justify-space-between mb-2" style="border-bottom: 1px solid gray;">
          <div class="d-flex align-center" style="gap: 10px">
            <v-icon @click="$router.push('/post-adopt-report')" color="black">mdi-arrow-left</v-icon>
            <div class="font-weight-bold text-body-1">{{ entity.name }}</div>
          </div>
          <p><span class="font-weight-bold">ID:</span> {{ entity.id }}</p>
        </div>
      </v-col>
      <v-row v-if="component" justify="center">
        <v-col cols="11" class="d-flex align-center" style="gap: 10px">
          <v-icon @click="handleBackClick" color="black">mdi-arrow-left</v-icon>
          <div class="text-h6">{{ component.name.replace(/([a-z])([A-Z])/g, "$1 $2") }}</div>
        </v-col>
        <v-col cols="11">
          <component :is="component" :entityId="+entityId" :entity="entity" />
        </v-col>
      </v-row>
      <v-row v-else>
        <v-col cols="12" md="6">
          <v-card>
            <v-card-text>
              <v-row>
                <v-col
                  @click="loadComponent(item.status)" cols="12" v-for="item in stats" :key="item.title"
                  v-ripple
                  style="border-bottom: 1px solid #00000012; cursor: pointer;"
                >
                  <v-sheet class="d-flex align-center justify-space-between">
                    <div class="text-h6">{{ item.name }}</div>
                    <v-chip small color="primary">{{ item.count }}</v-chip>
                  </v-sheet>
                </v-col>
              </v-row>
            </v-card-text>
          </v-card>
        </v-col>
      </v-row>
    </v-row>
  </div>
</template>

<style scoped>

</style>