<script>
import AdoptionsService from "@/services/adoptions.service";
import ClientsService from "@/services/clients.service";

export default {
  name: "TranscriptVerified",
  props: {
    entityId: {
      type: Number,
      required: true
    }
  },
  data: () => ({
    loading: false,
    resultsLoading: false,
    confirmLoading: false,
    results: [],
    status: 15,
    clients: [],
    statuses: [
      {
        value: 1,
        text: 'Awaiting IRS Payments'
      },
      {
        value: 2,
        text: 'Refile Needed'
      },
      {
        value: 3,
        text: 'Denial Received'
      },
      {
        value: 4,
        text: 'N/A'
      },
      {
        value: 5,
        text: 'Payment Received'
      }
    ],
    headers: [
      {
        text: 'Client',
        value: 'trade_name',
        filterable: true
      },
      {
        text: 'EIN',
        value: 'ein',
        filterable: true
      },
      {
        text: 'Legal Name',
        value: 'legal_name',
        filterable: true
      },
      {
        text: 'Date transcript verified',
        value: 'days_in_status',
      }
    ],
    selectedClient: [],
    resultDialog: false,
  }),
  methods: {
    async showTranscriptResult() {
      this.resultsLoading = true
      try {
        this.results = await ClientsService.getAdoptionQuarters(this.selectedClient[0].id)

        this.resultDialog = true
      } finally {
        this.resultsLoading = false
      }

    },
    async confirmResults() {
      this.confirmLoading = true;

      try {
        for (let i = 0; i < this.results.length; i++) {
          const quarter = this.results[i]
          if (!quarter.status_id) {
            continue
          }
          const payload = {
            status: quarter.status_id
          }

          await ClientsService.patchAdoptionQuarters(quarter.quarter_id, payload)
        }

        this.resultDialog = false;
        await this.getData()

      } finally {
        this.confirmLoading = false;
      }
    },
    async getData() {
      this.loading = true

      try {
        this.clients = await AdoptionsService.getAdoptionStatus(this.entityId, this.status)
        this.selectedClient = []
      } finally {
        this.loading = false
      }
    }
  },
  async mounted() {
    await this.getData()
  }
};
</script>

<template>
  <div>
    <v-dialog v-if="resultDialog" v-model="resultDialog" width="800">
      <v-card>
        <v-card-title>{{ selectedClient[0].trade_name }}</v-card-title>
        <br>
        <v-card-text>
          <v-row>
            <v-col cols="12" md="3" style="border: 1px solid gray" >
              <div class="text-center">Q1</div>
              <br>
              <v-select v-model="results[0].status_id" :items="statuses" item-value="value" item-text="text" />
            </v-col>
            <v-col cols="12" md="3" style="border: 1px solid gray">
              <div class="text-center">Q2</div>
              <br>
              <v-select v-model="results[1].status_id" :items="statuses" item-value="value" item-text="text" />
            </v-col>
            <v-col cols="12" md="3" style="border: 1px solid gray">
              <div class="text-center">Q3</div>
              <br>
             <v-select v-model="results[2].status_id" :items="statuses" item-value="value" item-text="text" />
            </v-col>
            <v-col cols="12" md="3" style="border: 1px solid gray">
              <div class="text-center">Q4</div>
              <br>
              <v-select v-model="results[3].status_id" :items="statuses" item-value="value" item-text="text" />
            </v-col>
          </v-row>
        </v-card-text>
        <v-card-actions>
          <v-btn @click="confirmResults" :loading="confirmLoading" color="primary">Confirm results</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-row>
      <v-col cols="12" md="10">
        <v-card>
          <v-card-text>
            <v-data-table
              :headers="headers"
              :items="clients"
              show-select
              v-model="selectedClient"
              single-select
              :loading="loading"
              disable-pagination
              hide-default-footer
            >
              <template v-slot:item.days_in_status="{ item }">
                <span v-if="item.days_in_status === 0">Today</span>
                <span v-else-if="item.days_in_status === 1">Yesterday</span>
                <span v-else>{{ item.days_in_status }} days ago</span>
              </template>
            </v-data-table>
          </v-card-text>
          <v-card-actions>
            <div class="d-flex justify-center" style="width: 100%">
              <v-btn color="primary" :loading="resultsLoading" @click="showTranscriptResult" :disabled="!selectedClient.length">Transcript results</v-btn>
            </div>
          </v-card-actions>
        </v-card>
      </v-col>
    </v-row>
  </div>
</template>

<style scoped>

</style>
