<script>
import ClientHeader from "@/components/Client/ClientHeader.vue";
import ClientService from "@/services/clients.service";

export default {
  name: "Emails",
  components: { ClientHeader },
  data: () => ({
    client: null,
    generating: 5, // 1 - intro, 2 - unresponsive 1st, 3 - unresponsive 2nd, 4 - unresponsive 3rd,
    sendLoading: false,
    clientId: "",
    adopterId: undefined,
    body: "",
    emailSent: false,
    CSAUploaded: false,
    sentEmails: [],
    emailsLoading: false,
    loading: true,
    managingAttorneysLoading: false,
    selectedManagingAttorneys: [],
    managingAttorneys: [],
    ccAOR: false,
    contactEmails: [],
    headers: [
      {
        text: "Client",
        value: "trade_name",
        filterable: true,
      },
      {
        text: "EIN",
        value: "ein",
        filterable: true,
      },
      {
        text: "Type of Email",
        value: "status_name",
        filterable: true,
      },
      {
        text: "Date",
        value: "date",
      },
    ],
  }),
  methods: {
    handleGoBack() {
      this.$router.push(`/post-adopt-report/${this.adopterId}#intro-email`);
    },
    async getOutstanding() {
      const res = await ClientService.getUploadReqDocs(this.clientId);
      const csa = res.find(doc => doc.document_name === "Client services agreement"); // CSA

      this.CSAUploaded = !!csa.document_status;
    },
    async getCurrentClient() {
      this.client = await ClientService.getOneClient(this.clientId);
    },

    getTemplate(legal_name) {
      switch (this.generating) {
        case 1:
          this.body = "<p>Dear "+legal_name+",</p><br/><p>We hope you're doing well. You are receiving this email because you were initially introduced to us by Leggett Clemons Crandall, PLLC.  I am with Frascogna Law Group, and I (alongside Dallas Anthony & Jeffords, PLLC, and Dayes Law Firm) are your legal partners in this ERC endeavor. I am reaching out with an important update regarding the status of your ERC claim.   As your legal partner in navigating the ERC process, Frascogna Law Group remains committed to striving for the best possible outcome for your business.</p><br/>" +
              "<br/><h3>Update on ERC Processing</h3><br/><p>The IRS recently announced it is processing over 400,000 ERC claims, totaling more than $10 billion. This is encouraging news, as it signals that some of your claims may progress. However, we also know that the IRS has begun issuing disallowances, particularly for the third quarter of 2021. We assess that many of these disallowances should be appealed, and we are ready to take the necessary steps to protect your interests. There is a 30-day deadline to appeal the disallowance, so let us know sooner rather than later.</p>" +
              "<br/><h3>Next Steps: Enhanced Status Tracking for Your Claim</h3><br/><p>To serve you more effectively, Frascogna Law Group, in collaboration with Dayes Law Firm, has implemented advanced systems to monitor the status of your ERC claim. This enhanced process involves filing an IRS Form 8821, which allows us to directly check the status of your claim and proactively identify any issues that may arise. While we cannot guarantee exact timelines, this approach enables us to confirm that your claim is in process, identify any possible problems, and stay informed of any relevant IRS notices.</p>"+
              "<br/><h3>Action Required</h3><br/><p>If we have not yet filed Form 8821 on your behalf, please get in touch with us at your earliest convenience at sblanton@frascognalaw.com so we can initiate this critical step. If we have already begun this process for your business, please stay connected with us for further updates, and do not hesitate to reach out with any questions.</p>"+
              "<br/><h3>Our Commitment to You</h3><br/><p>We understand that this has been a lengthy and challenging process, and we genuinely appreciate your patience as we work with federal systems. We remain confident that ERC funds are worth pursuing, and we are here to advocate for you every step of the way.</p>" +
              "<br/><p><em>Please note that if we do not hear from you within the next 30 days, we will follow up via email, phone, and written letter.</em></p><br/>"+
              "<br/><p><em>Thank you for trusting us to help you with your ERC claim. We look forward to continuing our work together to secure what your business deserves.</em></p><br/>" +
              "<br/><p>Warm regards,</p><br/>";
          break;
        case 2:
          this.body = "<p>Hello [FIRST_NAME] [LAST_NAME],</p> <p>First attempt</p>";
          break;
        case 3:
          this.body = "<p>Hello [FIRST_NAME] [LAST_NAME],</p> <p>Second attempt</p>";
          break;
        case 4:
          this.body = "<p>Hello [FIRST_NAME] [LAST_NAME],</p> <p>Third attempt</p>";
          break;
      }
    },
    async getSentEmails() {
      this.emailsLoading = true;
      if (!this.adopterId) {
        return;
      }

      try {
        this.sentEmails = await ClientService.getSentEmails(this.adopterId);
      } finally {
        this.emailsLoading = false;
      }
    },
    async send() {
      this.sendLoading = true;
      const {role, id, ...aor } = this.client.attorney_of_record;
      let managingAttorneys = this.selectedManagingAttorneys.map(item => {
        return {first: item.first, last: item.last, email: item.email}
      });

      let contacts = this.contactEmails.map(item => {
        return {email: item}
      })

      let cc = managingAttorneys.concat(contacts)

      if (this.ccAOR)
        cc.push(aor)

      try {
        const payload = {
          type: this.generating,
          body: this.body,
          cc
        };

        await ClientService.sendAdoptionEmail(this.clientId, payload);
        this.$store.dispatch("snackbar/showSnackbarMessage", {
          message: "Email sent successfully!",
          duration: 4000,
          mode: "success",
        });
        this.emailSent = true;
        this.getSentEmails();
      } finally {
        this.sendLoading = false;
      }
    },
    async getManagingAttorneys() {
      this.managingAttorneysLoading = true;

      try {
        const entity = await ClientService.getEntityById(this.client.managing_firm.id);
        this.managingAttorneys = entity.users.filter(item => item.role_id === 4);
      } finally {
        this.managingAttorneysLoading = false;
      }
    },
    getCardTitle() {
      let title;
      // 1 - intro, 2 - unresponsive 1st, 3 - unresponsive 2nd, 4 - unresponsive 3rd,
      switch (this.generating) {
        case 1:
          title = "Introduction Email"
          break;
        case 2:
          title = "Unresponsive 1st"
          break;
        case 3:
          title = "Unresponsive 2nd"
          break;
        case 4:
          title = "Unresponsive 3rd"
          break;
      }

      return title
    },
    validateEmail(email) {
      return String(email)
          .toLowerCase()
          .match(
              /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|.(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
          );
    },
    handleInput(items) {
      const item = items[items.length - 1]
      if (!this.validateEmail(item))
        this.contactEmails.splice(this.contactEmails.indexOf(item), 1)
    }
  },
  async mounted() {
    this.clientId = this.$route.params.clientId;
    this.adopterId = this.$route.query.adopterId;
    await this.getCurrentClient();
    await this.getOutstanding();
    this.loading = false;

    this.getManagingAttorneys();
    this.getSentEmails();
  },
  watch: {
    generating() {
      this.getTemplate(this.client.legal_name);
    },
  },
};
</script>

<template>
  <div>
    <ClientHeader :client="client" />
    <v-row v-if="loading">
      <v-col>
        <v-progress-circular
          :size="30"
          :width="3"
          color="primary"
          indeterminate
        ></v-progress-circular>
      </v-col>
    </v-row>
    <v-container v-else>
      <v-row>
        <v-col>
          <v-alert type="success" dense v-if="emailSent && adopterId">
            Email sent successfully! Go back to <a :href="`/post-adopt-report/${adopterId}`">List of clients</a>.
          </v-alert>
        </v-col>
      </v-row>
      <v-row align="center">
        <v-col cols="auto">
          <v-btn color="primary" @click="generating = 1">Generate Introduction</v-btn>
        </v-col>
        <v-col cols="auto">
          <v-btn color="primary" @click="generating = 2">Generate Unresponsive 1st</v-btn>
        </v-col>
        <v-col cols="auto">
          <v-btn color="primary" @click="generating = 3">Generate Unresponsive 2nd</v-btn>
        </v-col>
        <v-col cols="auto">
          <v-btn color="primary" @click="generating = 4">Generate Unresponsive 3rd</v-btn>
        </v-col>
      </v-row>
      <v-row>
        <v-col>
          <v-card v-if="generating !== 5">
            <v-card-title>
              <div style="width: 100%">
                <v-row align="center">
                  <v-col cols="auto">{{ getCardTitle() }}</v-col>
                  <v-col cols="auto">
                    <v-checkbox v-model="ccAOR" label="CC AOR" />
                  </v-col>
                  <v-col cols="auto">
                    <v-select
                      hide-details
                      :loading="managingAttorneysLoading"
                      v-model="selectedManagingAttorneys"
                      item-text="email"
                      key="id"
                      return-object
                      :items="managingAttorneys"
                      multiple
                      outlined
                      chips
                      dense
                      label="CC Managing Attorney"
                    />
                  </v-col>
                  <v-col cols="12">
                    <v-combobox
                      v-model="contactEmails"
                      chips
                      clearable
                      label="Add contacts"
                      @input="handleInput"
                      placeholder="Press TAB or ENTER after each email"
                      outlined
                      dense
                      hide-details
                      multiple
                    >
                      <template v-slot:selection="{ attrs, item, select, selected }">
                        <v-chip
                            v-bind="attrs"
                            :input-value="selected"
                            close
                            @click="select"
                            @click:close="contactEmails.splice(contactEmails.indexOf(item), 1)"
                        >
                          <strong>{{ item }}</strong>&nbsp;
                        </v-chip>
                      </template>
                    </v-combobox>
                  </v-col>
                </v-row>
              </div>
            </v-card-title>
            <v-card-text>
              <VueEditor v-model="body" />
              <br>
              <v-alert v-if="!CSAUploaded" type="error" dense>
                CSA document is not uploaded for client, upload it from
                <a :href="`/clients/${clientId}/documents`">documents</a>
                tab.
              </v-alert>
              <v-alert v-else type="success" dense>
                CSA document is uploaded for client.
              </v-alert>
            </v-card-text>
            <v-card-actions>
              <v-row align="center" justify="end">
                <v-col cols="auto">
                  <v-btn @click="send" :disabled="!CSAUploaded" :loading="sendLoading" color="primary">
                    Send
                    <v-icon right>mdi-send</v-icon>
                  </v-btn>
                </v-col>
              </v-row>
            </v-card-actions>
          </v-card>
        </v-col>
      </v-row>
      <v-row v-if="adopterId">
        <v-col cols="12">
          <v-card>
            <v-card-text>
              <v-data-table
                :headers="headers"
                :items="sentEmails"
                :loading="emailsLoading"
                disable-pagination
                hide-default-footer
                item-key="id"
              >
                <template #item.date="{ item }">
                    {{ new Date(new Date().setDate(new Date().getDate() - item.days_in_status)).toLocaleDateString() }}
                </template>
              </v-data-table>
            </v-card-text>
          </v-card>
        </v-col>
        <v-col>
          <v-btn @click="handleGoBack" color="primary"><v-icon>mdi-arrow-left</v-icon> Go back to emails report</v-btn>
        </v-col>
      </v-row>
    </v-container>
  </div>
</template>

<style scoped>

</style>
